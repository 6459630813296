$breakpoints: (small: 320px, medium: 736px, large: 1180px);

@mixin mi_createCol($i, $numberOfCols, $gutter:$gutter){
	$percentage: percentage(calc($i/$numberOfCols)); // Calculate how wide this col should be in %
	flex: 0 0 calc(#{$percentage} - #{$gutter}); // Take the percentage minus the gutter to separate cols appropriately
	width: calc(#{$percentage} - #{$gutter}); // Allows IE9 to understand the column width
}

.container {
	width: 1180px;
	margin: 0 auto;
    @include media("<=large") {
        width: 100%;
        padding: 0 calc($gutter/2);
    }
}

.grid {
	display: flex;
	flex-wrap: wrap;
	flex-flow: row wrap;
	width: calc(100% + #{$gutter});
	margin-left: -#{calc($gutter/2)};
	overflow: visible;

	/* Clear for old browsers that are using clear */
	&:after {
		content: " ";
		display: block;
		clear: both;
	}

	@for $i from 1 to $numberOfCols+1 {
        @include media(">large") {
            .grid__block--l#{$i} {
				@include mi_createCol($i, $numberOfCols);
			}
        }
        @include media("<=large") {
            .grid__block--m#{$i} {
				@include mi_createCol($i, $numberOfCols);
			}
        }
        @include media("<=medium") {
            .grid__block--s#{$i} {
				@include mi_createCol($i, $numberOfCols);
			}
        }
        @include media("<medium") {
            .grid__block--xs#{$i} {
				@include mi_createCol($i, $numberOfCols);
			}
        }
	}

    &--centered {
        justify-content: center;
    }

    &--blocks {
        margin: 0;
        width: calc(100% + #{$gutter});
        margin-left: -#{calc($gutter/2)};
		.grid__block {
			margin: 0;
            margin-left: calc($gutter/2);
            flex: 0 0 calc(52.5% - #{$gutter});
        	width: calc(52.5% - #{$gutter});
		}
    }

    /* Make a gutterless 12 column grid system */
	&--no-gutter {
		margin: 0;
		width: 100%;
		.grid__block {
			margin: 0;
		}
        @for $i from 1 to $numberOfCols+1 {
            @include media(">large") {
                .grid__block--l#{$i} {
    				@include mi_createCol($i, $numberOfCols, 0px);
    			}
            }
            @include media("<=large") {
                .grid__block--m#{$i} {
    				@include mi_createCol($i, $numberOfCols, 0px);
    			}
            }
            @include media("<=medium") {
                .grid__block--s#{$i} {
    				@include mi_createCol($i, $numberOfCols, 0px);
    			}
            }
    	}
	}

	&__block {
		flex: 0 1 auto;
		margin: 0 calc($gutter/2);
		float: left; // Old browser support
	}

	/* Adding this block means child elements don't span 100% height. Useful for some sidebars */
	&__no-grow {
		width: 100%;
	}
}
