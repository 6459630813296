.page-title {
    background: color('navy');
    padding: $spacing*1.5;
    @include media("<large") {
        padding: $spacing;
    }
    &__heading {
        color: color('white');
        font-size: 2.125em;
        text-align: center;
        @include media("<large") {
            font-size: 1.5em;
        }
    }
}
