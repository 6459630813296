.content {
    margin: $spacing*4 0;
	color: color('grey', darkest);
	&--booking {
		@include media("<=small") {
			> .container {
				padding: 0px !important;
				.grid {
					margin-left: 0px;
					width: 100%;
					.grid__block {
						width: 100%;
						flex: 0 0 100%;
						margin: 0px;
					}
				}
			}
		}
	}

    ul {
        list-style: disc;
        margin-left: calc($spacing/2);

        li {
            font-size: 14px;
            line-height: 2;
            position: relative;


        }
    }

    strong {
        color: color('navy');
    }

    @include media("<large") {
        margin: $spacing*2 0 $spacing;
    }
    p, li, ul {
        line-height: 1.7;
        margin-bottom: calc($spacing*1);
        font-size: 0.9em;
        a {
            transition: 0.1s linear color;
            &:hover {
                color: color('teal');
            }
        }
    }
    li {
        margin-bottom: calc($spacing*0.5);
    }


    &__image {
        margin-bottom: $spacing*1.5;
        display: block;
    }
    &__heading {
        color: color('navy');
        font-size: 1.375em;
        margin: $spacingLarge 0 $spacing;
        position: relative;
        &:after {
            content: "";
            height: 1px;
            width: $spacing*3;
            display: block;
            left: 0;
            position: relative;
            background: color('navy');
            margin: $spacing 0;
        }

        &--small {
            font-size: 1.15em;
            margin: calc($spacing/3) 0;
            &:after {
                display: none;
            }
        }
    }
    .faq-category {
        margin-bottom: $spacingLarge*2;
        @include media("<large") {
            margin-bottom: $spacingLarge;
        }
    }
    .faq-block {
        color: color('grey', darkest);
        margin-top: $spacingLarge;
        &--small {
            margin-top: 0;
            margin-bottom: $spacing;
            padding-left: $spacing;
            p {
                margin-bottom: calc($spacing/2);
                font-size: 0.8em;
                &:first-child {
                    font-size: 0.85em;
                    position: relative;
                    &:before {
                        content: "•";
                        font-weight: bold;
                        position: absolute;
                        right: 100%;
                        margin-right: calc($spacing/2);
                        top: 0;
                    }
                }
            }
        }
    }
}
