.slick {

	&-active {

		button {
			background-color: color('white');
		}
	}

	&-dots {
		margin-top: 30px;
		text-align: center;

		li {
			display: inline-block;
			margin: 0 5px;

			button {
				background-color: color('white');
				border: 1px solid color('white');
				border-radius: 50%;
				display: inline-block;
				cursor: pointer;
				font-size: 0;
				height: 14px;
				line-height: 18px;
				opacity: 0.3;
				outline: none;
				padding: 0;
				width: 14px;

				&:hover {
					opacity: 1;
				}
			}

			&.slick-active {

				button {
					opacity: 1;
				}
			}
		}
	}

	&-initialized {

		.slick-slide {
			display: flex;
		}
	}

	&-slide {
		height: auto;
		outline: none;
	}

	&-slider {
		user-select: text;
	}

	&-track {
		display: flex;
	}
}
