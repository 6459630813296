.site-header {
  background-color: color("black");
  padding: ($spacing) 0 ($spacing - 3);
  position: relative;
  z-index: 15;
  .grid__block {
    // @include media(">=medium","<large") {
    // text-align: center;
    //     &:first-child {
    //         margin-bottom: $spacing/2;
    //     }
    // }
  }
  &__nav {
    position: relative;
    z-index: 1;
    .nav-items__list {
      @include clearfix;
      // @include media(">=medium","<large") {
      //     float: none !important;
      //     display: inline-block;
      // }
      &--end {
        float: right;
        @include media("<large") {
          display: none;
        }
        li {
          margin-left: 0 !important;
          &:not(:last-child) {
            border-right: 1px solid color("grey", dark);
            padding-right: $spacing;
            margin-right: $spacing;
          }
          strong {
            position: relative;
            display: inline-block;
            top: 1px;
          }
          a:not(.nav-items__phone-number) {
            font-weight: normal;
            text-decoration: none;
            padding-right: 9px + calc($spacing/2);
            position: relative;
            color: color("blue") !important;
            &:hover {
              border-bottom: 1px solid color("blue") !important;
            }
            svg {
              fill: color("blue");
              height: 16px;
              width: 9px;
              position: absolute;
              right: 0;
              top: -1px;
            }
          }
        }
      }
      li {
        float: left;
        line-height: 1;
        margin-left: $spacing * 1.5;
        color: color("white");
        @include media("<large") {
          display: none;
          &.logo-item {
            display: block;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        a:not(.nav-items__phone-number) {
          color: color("grey", light);
          font-size: 0.85em;
          padding-bottom: 3px;
          text-decoration: none;
          border-bottom: 1px solid transparent;
          transition: 0.1s linear border;
          display: inline-block;
          &:hover {
            border-bottom: 1px solid color("grey");
          }
          &.active {
            border-bottom: 1px solid color("grey");
          }
        }
        a.nav-logo {
          display: inline-block;
          line-height: 1;
          @include hide-text();
          background: transparent url("/web/assets/img/site-logo.svg") no-repeat;
          background-size: contain;
          height: 14px;
          width: 160px;
          @media only screen and (max-width: 350px) {
            width: 120px;
          }
          &:hover {
            border-bottom: none;
          }
        }
      }
    }
  }

  
}

body.mobile-nav--closed {
  .nav-toggle {
    background: color("teal");
    color: color('navy');
    .icon--closed {
      display: block !important;
    }
  }
}

body.mobile-nav--open {
  .nav-toggle {
    background: color("navy");
    .icon--open {
      display: block !important;
    }
  }
}

.nav-items__phone-text {
  font-size: 14px;
}

.nav-items__phone-number {
  text-decoration: underline;
  font-size: 14px;
  color: inherit;
}

.header__call-panel {
  background: white;
  -webkit-box-shadow: 3px 11px 23px -8px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 11px 23px -8px rgba(0, 0, 0, 0.25);
  padding: 16px 20px;
  border-radius: 6px;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  left: -38%;

  //   arrow up
  &::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid white;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -100%, 0);
  }
}

.header__call-panel {
  display: none;
  ul {
    padding-inline-start: 0px;
    margin-block-start: 0px;
  }
  li:not(:last-child) {
    margin-bottom: 20px;
  }
}

.header__call-panel.header__call-panel--open {
  display: block;
}

.header-links__link.header-links__link--quick-book {
  span {
    font-size: 13px;
    line-height: 1;
    font-family: $fontFamily;
  }
}
