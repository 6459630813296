form {
    .freeform-row {
		@include media('<large') {
			flex-flow: row wrap;
		}
        &:first-child {
            margin-top: $spacing * -1;
        }
        .freeform-column {
            margin-top: $spacing;
            padding: 0;
			flex: 1;
			h3 {
				font-weight: bold;
				margin-top: $spacing;
			}
			p {
				line-height: 1.7;
				margin-bottom: $spacing*1.5;
				font-size: 0.9em;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					transition: 0.1s linear color;
					&:hover {
						color: color('black');
					}
				}
			}
        }
    }
    .freeform-label {
        font-size: 0.85em;
        font-weight: bold;
        color: color('grey', darkest);
        margin-bottom: calc($spacing/2);
        float: left;
        &.freeform-required {
            position: relative;
            padding-left: calc($spacing/1.5);
            &:before {
                content: "•";
                display: block;
                position: absolute;
                left: 0;
                top: -1px;
                line-height: 1;
                font-weight: bold;
                font-size: 1.25em;
                color: #ED7D7D;
            }
            &:after {
                display: none !important;
            }
        }
    }
    .freeform-label-push {
        margin-right: calc($spacing/2);
    }
    .freeform-instructions {
        float: left;
        margin-bottom: calc($spacing/2) !important;
    }
    .alert {
        p {
            font-weight: bold;
            font-size: 0.85em;
            margin-bottom: $spacing;
        }
        &--succes p {

        }
        &--error p {
            color: #ED7D7D;
        }
    }

    input[type=text], input[type=phone], input[type=email], textarea, select {
        line-height: 1;
        width: 80%;
        height: auto;
        padding: calc($spacing/1.5) calc($spacing/2);
        font-size: 1em;
        border: 1px solid color('grey');
        border-radius: 0;
        outline: none;
        resize: none;
        margin: 0;
        transition: 0.1s linear border;
        &.has-error {
            border: 1px solid #ED7D7D;
        }
        &:focus {
            border: 1px solid color('blue');
        }
    }
    textarea {
        min-height: 113px;
    }
    select {
        appearance: none;
        background: transparent;
        border-radius: 0;
    }
    .select-wrapper {
        position: relative;

        svg {
            position: absolute;
            top: 54%;
            right: $spacing;
            transform: rotate(90deg);
            height: 16px;
            width: 9px;
            fill: color('grey', dark);
        }
    }
}
