body.mobile-nav--open {
    @include media("<=large") {
        .mobile-nav {
			transform: translateY(100%);
			visibility: hidden;
        }
    }
    .mobile-nav {
		transform: translateY(0%);
		visibility: visible;
    }
}

.mobile-nav {
    position: absolute;
    z-index: 14;
    width: 100%;
    height: auto;
    padding: $spacing 0;
    background: color('grey', super);
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.16);
    transition: 0.4s ease-in-out all;
	transform: translateZ(0) translateY(-100%);
	visibility: hidden;
    &__blocks {
        display: flex;
    	flex-wrap: wrap;
    	flex-flow: row wrap;
        width: calc(100% + #{calc($gutter/2)});
        margin-left: -#{calc($gutter/2)};
        &--block {
            margin: 0;
            padding-left: calc($gutter/2);
            flex: 0 0 50%;
        	width: 50%;
            a {
                border: 1px solid color('white', normal, 0.15);
                display: block;
                font-size: 0.85em;
                text-align: center;
                border-radius: 3px;
                padding: $spacing;
                color: color('white');
                text-decoration: none;
                margin-bottom: calc($gutter/2);
                &.active, &.child-active {
                    background: color('white', normal, 0.15);
                }
                .icon {
                    margin-bottom: calc($spacing/2);
                    svg {
                        height: 34px;
                        width: 100%;
                        fill: white;
                    }
                }

                
            }
        }
    }
    &__items {
        li {
            border: 1px solid color('white', normal, 0.15);
            border-top: 0;
            display: block;
            border-radius: 0px;
            &:first-child {
                border-top: 1px solid color('white', normal, 0.15);
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;
            }
            &:last-child {
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            &.active {
                background: color('white', normal, 0.15);
            }
        }
        a {
            text-align: center;
            padding: calc($spacing/1.25);
            display: block;
            font-size: 0.85em;
            color: color('white');
            text-decoration: none;
        }
    }
}
