.site-footer {
    border-top: 1px solid color('navy');
    padding-top: $spacing*5;
    @include media("<large") {
        padding-top: $spacingLarge;
    }
    &__nav {
        ul {
            li {
                float: none;
                border-top: 1px solid color('grey', light);
                &.heading {
                    color: color('navy');
                    font-weight: bold;
                    font-size: 1em;
                    border-top: 0;
                    line-height: 20px;
                    padding: $spacing 0;

                    & + li {
                        border-top: 0;
                    }
                    svg {
                        float: left;
                        display: block;
                        fill: color('navy');
                        height: 20px;
                        width: 25px;
                        margin-right: $spacing;
                    }
                }
                a {
                    color: color('black');
                    text-decoration: none;
                    display: block;
                    font-size: 0.85em;
                    padding: $spacing 0;
                    transition: 0.1s linear padding;
                    &:hover {
                        padding-left: calc($spacing/2);
                        svg.icon {
                            margin-right: calc($spacing/2);
                        }
                    }
                    svg.icon {
                        height: 16px;
                        width: 9px;
                        float: right;
                        margin-top: -2px;
                        fill: color('grey', dark);
                        transition: 0.1s linear margin;
                    }
                }
            }
        }
    }
}
