.vehicles {
	background: color('navy');
	padding: 64px 0;

	&__text {
		margin-top: 43px;
		text-align: center;

		p {
			color: color('white');
			font-size: 12px;
			line-height: 1.5;

			&:first-child {
				font-size: 16px;
			}
		}

		a {
			color: color('white');
		}
	}

	.container {
		width: 850px;

		@include media("<=large") {
			padding: 0 10px;
			width: 100%;
		}
    }
}
