.vehicle {
	background-color: color('white');
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(color('black'), 0.16);
	flex-direction: column;
	justify-content: space-between;
	margin: 0 calc($spacing/2);
	//min-height: 801px;
	padding: 30px;
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @include media(">=medium") {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    @include media(">=large") {
        padding-left: 50px;
        padding-right: 50px;
    }

	&__content {
        color: color('black');
        position: relative;

		p {
			font-size: 14px;
			line-height: 2;
		}

		ul {
			list-style: none;
			margin-left: calc($spacing/2);

			li {
				font-size: 14px;
				line-height: 2;
				position: relative;


			}
		}

		&--first {
			border-bottom: 1px solid #D8D8D8;
			margin-bottom: 30px;
			padding-bottom: 30px;
        }

        @include media('>=medium') {
            display: flex;
            flex-flow: nowrap row;
            justify-content: space-between;

            &--first,
            &--last {
                width: 50%;
            }

            &--first {
                border: none;
                margin-bottom: none;
                padding-bottom: none;
            }

            &--last {
                padding-left: 20px;
                border-left: 1px solid #D8D8D8;
            }
        }
    }

    &__left {
        @include media('>=medium') {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40%;
        }
    }

    &__right {
        @include media('>=medium') {
            width: 50%;
        }
    }

	&__heading {
		color: color('navy');
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;

		span {
			color: color('black');
			display: block;
			font-weight: normal;
		}
	}

	&__image {
		margin: 30px auto;
        max-width: 190px;
        max-height: 150px;

        @include media('>=medium') {
            max-width: 100%;
            margin-bottom: 0;
        }
    }

    &__columns {
        @include media('>=medium') {
            display: flex;
            flex-flow: nowrap row;
            justify-content: space-between;
        }
    }

	.button {
		margin-top: 30px;
		text-align: center;
        justify-content: center;
		width: 100%;
	}

	.ribbon {
		position: absolute;
		text-align: center;
		top: 0;
        left: 0;
		z-index: 1;
        font-size: 16px;
        background: #FEC601;
        color: color('navy');
        padding: 10px 0;
        transform: translateX(-30%) translateY(0%) rotate(-45deg);
        transform-origin: top right;
        &:before, &:after {
            content: '';
            position: absolute;
            top:0;
            margin: 0 -1px;
            width: 200%;
            height: 100%;
            background: #FEC601;
        }
        &:before {
            right:100%;
        }
        &:after {
            left:100%;
        }
	}
}
