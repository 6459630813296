@font-face {
    font-family: "Graphik Web";
    src: url("../fonts/Graphik-Semibold-Web.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Graphik Web";
    src: url("../fonts/Graphik-Regular-Web.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [x-cloak] {
        display: none !important;
    }

    .nav-menu-text a:hover {
        @apply tw-text-black;
    }

    .child-lists-inside ul {
        @apply tw-list-inside !important;
    }

    main {
        @apply tw-scroll-smooth;
    }
}

@layer utilities {
    .contact-popup:before {
        content: "";
        border-bottom: 10px solid #fff;
        border-left: 10px solid #0000;
        border-right: 10px solid #0000;
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%, -100%);
    }

    .new-container {
        @apply tw-mx-auto tw-max-w-[830px] tw-px-5;
    }

    .bust-out {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }

    @screen xl {
        .bust-out {
            margin-left: calc((50% + 8px) - 50vw);
            margin-right: calc((50% + 8px) - 50vw);
        }
    }
}
