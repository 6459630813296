body {
    font-family: $fontFamily;
    -webkit-font-smoothing: antialiased;
    -moz-font-feature-settings: "liga", "kern";
    -moz-font-feature-settings: "liga=1, kern=1";
    -ms-font-feature-settings: "liga", "kern";
    -o-font-feature-settings: "liga", "kern";
    -webkit-font-feature-settings: "liga", "kern";
    font-feature-settings: "liga", "kern";
    font-size: 16px;
    min-width: 320px;
}

html.locked-scrolling {
    overflow: hidden;
    height: 100%;
}

* {
    box-sizing: border-box;
}

a {
    color: color('navy');
    text-decoration: underline;
    &:hover {
        color: color('teal');
    }
}

strong,
b {
    font-weight: 600;
}

em,
i {
    font-style: italic;
}

.mtl-img {
    max-width: 100%;
    width: auto !important;
    height: auto;
    display: block;
}

// hr {
//   border: 0;
//   margin-bottom: $spacingRelative;
// }