a.button, button.button {
    appearance: none;
    background: color('teal');
    border-radius: 3px;
    padding: calc($spacing / 1.4);
    margin: 0px;
    font-weight: bold;
    color: color('navy');
    border: 0px;
    outline: none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    transition: 0.2s linear all;
    font-size: 0.85em;
    &:hover {
        background: color('yellow');
        color: color('navy');
        svg {
            transform: translateX(5px);
        }
    }
    &[disabled] {
        cursor: wait;
        background: color('grey', dark);
        &:hover {
            background: color('grey', dark);
        }
    }
    svg {
        width: 16px;
        height: 10px;
        margin-top: 1px;
        margin-left: calc($spacing/1.4);
        transition: all 0.3s;
        fill: currentColor;
    }
    &--secondary {
        background: color('white');
        &:hover {
            background: color('yellow');
            color: color('navy');
        }
    } 
    &--alternate-hover {
        &:hover {
            background: color('yellow');
            color: color('navy');
        }
    }
    &--large {
        padding: $spacing;
    }
    &--close {
        background: transparent;
        border: 1px solid color('white');
        padding: calc($spacing/2);
        &:hover {
            background: color('white');
            border: 1px solid color('white');
            color: color('black');
        }
    }
}
