.overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media('<large') {
        display: none !important;
    }
    &.active {
        display: flex;
    }
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 110;
        background: color('black', normal, 0.76);
    }
    &__content {
        position: relative;
        z-index: 120;
		min-width: 300px;
		max-height: 100vh;
		height: auto;
        display: none;
        &--active {
            display: flex;
        }
        .block {
			background: color('white');
			overflow: hidden;
			overflow-y: auto;
			max-height: 100vh;
			height: auto;
            .inner-content {
                width: 768px - ($spacing*2);
				padding: $spacingLarge;
				&--freeform {
					padding-top: $spacing;
				}
            }
        }
    }
    &__header {
        background: color('blue');
        padding: $spacing $spacingLarge;
        margin: 0;
        font-weight: bold;
        font-size: 1em;
        color: color('white');
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
            margin-left: $spacingLarge;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
