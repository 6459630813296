.booking-banner {
    background: color('grey', faint);
    padding: $spacingLarge 0;
    @include media("<large") {
        padding-top: $spacing;
    }
    &__link-block {
        margin-top: $spacing;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        @include media("<large") {
            display: block;
        }
        &:first-child {
            margin-top: 0;
            @include media("<large") {
                margin-top: $spacing;
            }
        }
        &:hover {
            .button {
                background: color('yellow');
                color: color('navy');
                svg {
                    margin-left: $spacing;
                }
            }
        }
        &--button {
            flex: 0 1 auto;
            padding: $spacing !important;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            @include media("<large") {
                display: block;
                width: 100%;
                border-top-left-radius: 0px !important;
                border-top-right-radius: 0px !important;
                border-bottom-left-radius: 3px !important;
                border-bottom-right-radius: 3px !important;
            }
        }
        &--locations {
            display: flex;
            flex: 1 0 auto;
            line-height: 21px;
            padding: $spacing;
            background: #000;
            color: color('black');
            background: color('white');
            border-radius: 3px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            justify-content: space-around;
            align-items: stretch;
            @include media("<large") {
                padding: calc($spacing/1.5);
                border-top-left-radius: 3px !important;
                border-top-right-radius: 3px !important;
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }
            > span {
                display: flex;
                clear: both;
                flex: 0 1 auto;
                margin-left: $spacing;
                font-size: 0.8em;
                &:first-child {
                    margin-left: 0;
                }
                &.middle {
                    svg {
                        fill: color('grey');
                    }
                }
                svg {
                    display: inline-block;
                    float: left;
                    height: 21px;
                    width: 23px;
                    &.leading {
                        margin-right: calc($spacing/1.5);
                        fill: color('black');
                    }
                }
            }
        }
    }
    &__string {
        font-size: 0.85em;
        color: color('black');
        text-align: center;
        border-top: 1px solid color('grey', light);
        padding-top: $spacingLarge;
        margin-top: $spacingLarge;
        a {
            font-weight: 600;
            transition: 0.1s linear color;
            &:hover {
                color: color('black');
            }
        }
    }
}
