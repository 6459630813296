.feature-block {
    margin-bottom: $spacingLarge;
    &__link {
        display: block;
        text-decoration: none;
        &:hover {
            .icon {
                margin-left: $spacing;
            }
        }
    }
    &__image {
        img {
            display: block;
            border-radius: 3px;
        }
    }
    &__title {
        .icon {
            fill: color('blue');
            height: 16px;
            width: 9px;
            display: inline-block;
            margin-left: calc($spacing/2);
            transition: 0.1s linear margin-left;
        }
        &--subtitle {
            position: relative;
            margin: $spacing 0 $spacing;
            &:after {
                content: "";
                height: 1px;
                width: $spacing*3;
                display: block;
                left: 0;
                position: relative;
                background: color('blue');
                margin: $spacing 0;
            }
            .content__heading {
                margin: 0;
                &:after {
                    display: none !important;
                }
            }
            p {
                padding: 0;
                margin: 0;
                font-weight: bold;
                text-transform: uppercase;
                color: color('grey',darkest);
                margin-top: calc($spacing/2);
                font-size: 0.8em;
            }
        }
    }
    &__table {
        > p {
            margin-bottom: calc($spacing/2);
        }
    }
}
