.footer-lower {
    padding: $spacingLarge*2 0;
    @include media('<medium') {
        padding: $spacingLarge 0;
    }
    &__nav {
        .nav-items__list {
            @include media('<=medium') {
                text-align: center;
            }
            &--right {
                float: right;
                @include media('<=medium') {
                    float: none;
                    border-top: 1px solid color('grey', light);
                    padding-top: $spacing;
                }
            }
            li {
                float: left;
                margin-left: $spacing*1.5;
                font-size: 0.8em;
                color: color('grey', darkest);
                @include media('<=medium') {
                    float: none;
                    display: block;
                    margin-left: 0;
                    margin-bottom: $spacing;
                }
                &:first-child {
                    margin-left: 0;
                }
                a {
                    color: color('grey', darkest);
                    text-decoration: none;
                    padding-bottom: 3px;
                    display: inline-block;
                    border-bottom: 1px solid transparent;
                    transition: 0.1s linear border;
                    &:hover {
                        border-bottom: 1px solid color('grey');
                    }
                }
            }
        }
    }
}
