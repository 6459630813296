.hero {
	height: 450px;
    background: color('black');
    position: relative;
    @include media('<large') {
        height: auto;
    }
    &--larger {
		height: 70vh;
		max-height: 700px;
        @include media("<large") {
            height: auto;
        }
	}
	&--smaller {
        display: none;
    }
    &__overlay {
        position: absolute;
        z-index: 10;
        bottom: 100px;
        width: 100%;
        @include media("<large") {
            position: relative;
            bottom: 0;
            background: color('grey', faint);
            padding: calc($gutter/2) 0;
        }
        &--title {
            font-size: 2.125em;
            line-height: 1.3;
            margin-bottom: $spacing;
            @include media("<large") {
                font-size: 1.55em;
            }
		}
		&--block {
			background: #fff;
			padding: $spacing;
			border-radius: 5px;
			@include media("<large") {
				box-shadow: none;
				padding: 0px;
				background:transparent;
            }
		}
        p {
            font-size: 0.85em;
            line-height: 1.8;
            margin-bottom: $spacing;
        }
    }
    &__image {
        z-index: 5;
        height: 100%;
        width: 100%;
        top: 0;
        position: absolute;
        background-size: cover;
        background-position: center center;
        @include media("<large") {
            height: 200px;
            position: relative;
        }
        &--top {
            background-position: center top;
        }
        &--center {
            background-position: center center;
        }
        &--bottom {
            background-position: center bottom;
        }
    }

	&__video {
		z-index: 6;
		height: 100%;
		width: 100%;
		top: 0;
		position: absolute;
		overflow: hidden;

		video {
			object-fit: cover;
			width: 100%;
		}

	}
}
